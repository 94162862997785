import React, { useEffect } from "react";
import { ethers } from "ethers";
import launchpadABI from '../../utils/constants/launchpadABI'
import { presale_Contract_Abi, vesting_Contract_Abi } from "../../utils/Api";
const launchpadBlockchainDetails = async (props) => {
  try {
    //abi I am using in my code

    // let provider = "";
    // if (props?.token_network == "BSC") {
    //   provider = new ethers.providers.JsonRpcProvider(
    //     "https://bsc-dataseed1.binance.org/"
    //   );
    // } else if (props?.token_network == "ETH") {
    //   provider = new ethers.providers.JsonRpcProvider(
    //     "https://mainnet.infura.io/v3/2e652aee0b074ecdb3230784854aa3ac"
    //   );
    // } else if (props?.token_network == "GOERLI") {
    //   provider = new ethers.providers.JsonRpcProvider(
    //     "https://goerli.infura.io/v3/122632949b0c40a6905ddad43a7ded72"
    //   );
    // } else if (props?.token_network == "BNBT") {
    //   provider = new ethers.providers.JsonRpcProvider(
    //     "https://data-seed-prebsc-1-s1.binance.org:8545"
    //   );
    // }
    // const { chainId } = await provider.getNetwork()
    // console.log('chainId is: ',chainId)

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let accounts = await provider.send("eth_requestAccounts", []);
    let account = accounts[0];
    const network = await provider.getNetwork();
    if (!account) {
      return;
    }
    const contract = new ethers.Contract(
      props.presale_addr,
      launchpadABI,
      provider
    );
    const private_sale_details = {};

    // get User deposit amount
    // const maxBuy = await contract.maxInvest();
    // const minBuy = await contract.minInvest();
    const getInfo = await contract.joinInfos(account);
    const hardCap = await contract.hardCap();
    const softCap = await contract.softCap();
    const listOfWhiteListUsers = await contract.listOfWhiteListUsers();

    const launchpadOwner = await contract.launchpadOwner();
    const listingPercent = await contract.listingPercent();
    const listingPrice = await contract.listingPrice();
    const listingTime = await contract.listingTime();
    const lockAfterCliffVesting = await contract.lockAfterCliffVesting();
    const manualListing = await contract.manualListing();
    const maxLiquidity = await contract.maxLiquidity();


    const paused = await contract.paused();
    const penaltyFee = await contract.penaltyFee();
    const poolType = await contract.poolType();
    const presaleRate = await contract.presaleRate();
    const raisedAmount = await contract.raisedAmount();
    const raisedFeePercent = await contract.raisedFeePercent();
    const routerAddress = await contract.routerAddress();
    const joinedUsers = await contract.getJoinedUsers();
    const startTime = await contract.startTime();
    const endTime = await contract.endTime();
    const state = await contract.state();
    const teamCliffVesting = await contract.teamCliffVesting();
    const teamFirstReleasePercent = await contract.teamFirstReleasePercent();
    const teamLockId = await contract.teamLockId();

    const teamTokenReleaseEachCycle = await contract.teamTokenReleaseEachCycle();
    const teamVestingPeriodEachCycle = await contract.teamVestingPeriodEachCycle();
    // const teamReleaseEachCycle =  await contract.teamReleaseEachCycle();

    // const teamTotalVestingTokens =  await contract.teamTotalVestingTokens();
    // const teamSoldTokens =  await contract.teamSoldTokens();


    // const vestingPeriodEachCycle =  await contract.vestingPeriodEachCycle();
    // const virtualLock =  await contract.virtualLock();
    const whitelistPool = await contract.whitelistPool();
    const whitelistedTime = await contract.whitelistedTime();

    // private_sale_details.paused = paused

    // private_sale_details.raisedFeePercent = routerAddress;

    // private_sale_details.whitelistedTime = getTimeFormat(
    //   whitelistedTime._hex
    // );

    // private_sale_details.whitelistPool = ethers.utils.formatEther(
    //   parseInt(whitelistPool._hex).toString()
    // );

    // private_sale_details.virtualLock = virtualLock

    // private_sale_details.vestingPeriodEachCycle = ethers.utils.formatEther(
    //   parseInt(vestingPeriodEachCycle._hex).toString()
    // );

    // private_sale_details.teamSoldTokens = ethers.utils.formatEther(
    //   parseInt(teamSoldTokens._hex).toString()
    // );

    // private_sale_details.startTime = getTimeFormat(
    //   startTime._hex
    // );

    // private_sale_details.teamTotalVestingTokens = ethers.utils.formatEther(
    //   parseInt(teamTotalVestingTokens._hex).toString()
    // );

    // private_sale_details.teamReleaseEachCycle = ethers.utils.formatEther(
    //   parseInt(teamReleaseEachCycle._hex).toString()
    // );

    // private_sale_details.teamVestingPeriodEachCycle = ethers.utils.formatEther(
    //   parseInt(teamVestingPeriodEachCycle._hex).toString()
    // );

    // private_sale_details.teamTokenReleaseEachCycle = ethers.utils.formatEther(
    //   parseInt(teamTokenReleaseEachCycle._hex).toString()
    // );

    // private_sale_details.teamLockId = ethers.utils.formatEther(
    //   parseInt(teamLockId._hex).toString()
    // );

    // private_sale_details.teamFirstReleasePercent = ethers.utils.formatEther(
    //   parseInt(teamFirstReleasePercent._hex).toString()
    // );

    // private_sale_details.teamCliffVesting = ethers.utils.formatEther(
    //   parseInt(teamCliffVesting._hex).toString()
    // );

    // private_sale_details.getjoinedusers = getjoinedusers

    private_sale_details.state = parseInt(state._hex, 16);

    // private_sale_details.endTime = getTimeFormat(
    //   endTime._hex
    // );

    // private_sale_details.raisedFeePercent = ethers.utils.formatEther(
    //   parseInt(raisedFeePercent._hex).toString()
    // );
    private_sale_details.raisedAmount = ethers.utils.formatEther(
      parseInt(raisedAmount._hex).toString()
    );

    private_sale_details.presaleRate = ethers.utils.formatEther(
      presaleRate._hex.toString()
    );

    // private_sale_details.penaltyFee = ethers.utils.formatEther(
    //   parseInt(penaltyFee._hex).toString()
    // );


    // private_sale_details.poolType = ethers.utils.formatEther(
    //   parseInt(poolType._hex).toString()
    // );

    const max_liquidity = ethers.BigNumber.from(maxLiquidity._hex);
    private_sale_details.maxLiquidity = ethers.utils.formatEther(max_liquidity);
    
    // private_sale_details.manualListing = manualListing

    // private_sale_details.lockAfterCliffVesting = ethers.utils.formatEther(
    //   parseInt(lockAfterCliffVesting._hex).toString()
    // );
    // private_sale_details.launchpadOwner = launchpadOwner

    // private_sale_details.listingTime = getTimeFormat(
    //   listingTime._hex
    // );

    // private_sale_details.listingPercent = ethers.utils.formatEther(
    //   parseInt(listingPercent._hex).toString()
    // );
    const listingPrice1 = ethers.BigNumber.from(listingPrice._hex);
    private_sale_details.listingPrice = ethers.utils.formatEther(listingPrice1);

    // private_sale_details.hardCap = ethers.utils.formatEther(
    //   parseInt(hardCap._hex).toString()
    // );

    // private_sale_details.softCap = ethers.utils.formatEther(
    //   parseInt(softCap._hex).toString()
    // );

    private_sale_details.totalInvestment = ethers.utils.formatEther(
      parseInt(getInfo[0]._hex).toString()
    );
    private_sale_details.claimedTokens = ethers.utils.formatEther(
      parseInt(getInfo[1]._hex).toString()
    );
    private_sale_details.totalTokens = ethers.utils.formatEther(
      parseInt(getInfo[2]._hex).toString()
    );
    private_sale_details.refund = getInfo[3]



    // private_sale_details.maxBuy = ethers.utils.formatEther(
    //   parseInt(maxBuy._hex).toString()
    // );

    // private_sale_details.minBuy = ethers.utils.formatEther(
    //   parseInt(minBuy._hex).toString()
    // );
    // private_sale_details.listOfWhiteListUsers = listOfWhiteListUsers;
    private_sale_details.joinedUsers = joinedUsers;
    // console.log('private_sale_details ', private_sale_details)
    return private_sale_details;
  } catch (error) {
    // console.log('error 8is: ', error)
  }
};
const getTimeFormat = (time) => {
  const timeInSeconds = parseInt(time, 16);
  return timeInSeconds;
};
const getTotalContributorsList = async (contract_address, wallet_address) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      contract_address,
      launchpadABI,
      provider
    );
    const get_user_deposit_amount = '';
    const get_user_deposit = await contract.joinInfos(wallet_address);
    get_user_deposit_amount = ethers.utils.formatEther(
      parseInt(get_user_deposit[0]._hex).toString()
    );
    return get_user_deposit_amount;
  } catch (error) {
    console.log('error 8is: ', error)
  }
};
export { launchpadBlockchainDetails, getTotalContributorsList };
